export enum UserActionTypes {
    UPDATE_USER = 'UPDATE_USER',
    GET_ALL_USERS = 'GET_ALL_USERS',
    GET_USER_BY_ID = 'GET_USER_BY_ID',
    GET_USER_BY_CHAT_ID = 'GET_USER_BY_CHAT_ID'
}

export enum ItemActionTypes {
    CREATE_ITEM = 'CREATE_ITEM',
    UPDATE_ITEM = 'UPDATE_ITEM',
    GET_ALL_ITEMS = 'GET_ALL_ITEMS',
    FILTER_ITEM = 'FILTER_ITEM',
    GET_ITEM_BY_ID = 'GET_ITEM_BY_ID',
    GET_MY_ITEMS = 'GET_MY_ITEMS'
}

export enum AdvertisementActionTypes {
    CREATE_ADVERTISEMENT = 'CREATE_ADVERTISEMENT',
    UPDATE_ADVERTISEMENT = 'UPDATE_ADVERTISEMENT',
    GET_ALL_ADVERTISEMENTS = 'GET_ALL_ADVERTISEMENTS',
    GET_ADVERTISEMENT_BY_ID = 'GET_ADVERTISEMENT_BY_ID'
}

export enum CityActionTypes {
    CREATE_CITY = 'CREATE_CITY',
    UPDATE_CITY = 'UPDATE_CITY',
    GET_ALL_CITIES = 'GET_ALL_CITIES',
    GET_CITY_BY_ID = 'GET_CITY_BY_ID',
}

export enum CategoryActionTypes {
    CREATE_CATEGORY = 'CREATE_CATEGORY',
    UPDATE_CATEGORY = 'UPDATE_CATEGORY',
    GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES',
    GET_CATEGORY_BY_ID = 'GET_CATEGORY_BY_ID',
}

export enum SubscribeActionTypes {
    CREATE_SUBSCRIBE = 'CREATE_SUBSCRIBE',
    UPDATE_SUBSCRIBE = 'UPDATE_SUBSCRIBE',
    GET_ALL_SUBSCRIBES = 'GET_ALL_SUBSCRIBES',
    GET_SUBSCRIBE_BY_ID = 'GET_SUBSCRIBE_BY_ID'
}

export enum SubCategoryActionTypes {
    CREATE_SUBCATEGORY = 'CREATE_SUBCATEGORY',
    UPDATE_SUBCATEGORY = 'UPDATE_SUBCATEGORY',
    GET_ALL_SUBCATEGORIES = 'GET_ALL_SUBCATEGORIES',
    GET_SUBCATEGORY_BY_ID = 'GET_SUBCATEGORY_BY_ID',
    GET_SUBCATEGORY_BY_CATEGORY = 'GET_SUBCATEGORY_BY_CATEGORY',
}


